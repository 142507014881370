/* eslint-disable react/no-array-index-key */
import React from 'react'

// Images
import SSBCrossIcon from 'img/SSB_cross_icon.svg'

// Components

// Third Party
import styled, { css } from 'styled-components'

const TypesWrapper = styled.div`
`

const Wrapper = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.ms};
  }
`

const Type = styled.div`
  transition: 0.25s;
  cursor: pointer;

  ${props => props.isSelected ? css`
    border: 1px solid ${props.theme.color.border};
    background: ${props.theme.color.gradient_background};
    background: ${props.theme.color.gradient};
    color: ${props.theme.color.text.light};
    position: relative;
    border-radius: 19.5px;
    padding-top: 2px;
    padding-bottom: 2px;
    }
  ` : css`
    border: 1px solid ${props.theme.color.border};
    border-radius: 19.5px;
    padding-top: 2px;
    padding-bottom: 2px;
  `}

  p {
    font-size: ${props => props.theme.font.size.sm};
    height: 30px;
    margin: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
  }

  :active {
  }
`

const Cross = styled.img`
  height: 23px;
  width: 23px;
  top: -12px;
  right: -2px;
`

const Filter = ({ types, fases, filter, setFilter, data, className }) => {
  
  const handleFilter = (e, index) => {
    let element;

    if (types) {
      if (document !== undefined) {
        element = document.getElementById(`types_${index}`)
      }
    }

    if (fases) {
      if (document !== undefined) {
        element = document.getElementById(`fase_${index}`)
      }
    }
    
    if (filter.find((o) => o.value === parseInt(element.getAttribute('value'), 10))) {
      setFilter([...filter.filter((o) => o.value !== parseInt(element.getAttribute('value'), 10))])
    } else {
      setFilter([...filter, {value: parseInt(element.getAttribute('value'), 10), label: element.innerText}])
    }
  }

  return (
    <Wrapper>
      {types && (
        <h2 className="pb-2 text-uppercase font-weight-xl color-text-secondary">Type bouw:</h2>
      )}
      {fases && (
        <h2 className="pb-2 text-uppercase font-weight-xl color-text-secondary">Fase:</h2>
      )}
      <TypesWrapper className={`d-flex flex-wrap ${className ? `${className}` : ``}`}>
  
        {data.edges.map((item, index) => (
          <Type onClick={(e) => handleFilter(e, index)} isSelected={!!filter.find((o) => o.value === item.node.wordpress_id)} className="mr-2 mb-2 mr-lg-3 mb-lg-3" key={index}>
            {!!filter.find((o) => o.value === item.node.wordpress_id) && (
              <Cross onClick={(e) => handleFilter(e, index)} src={SSBCrossIcon} alt="" className="position-absolute d-block" />
            )}
            {types && (
              <p id={`types_${index}`} className="px-3 font-weight-xl d-flex justify-content-center align-items-center" value={item.node.wordpress_id}>{item.node.name}</p>
            )}
            {fases && (
              <p id={`fase_${index}`} className="px-3 font-weight-xl d-flex justify-content-center align-items-center" value={item.node.wordpress_id}>{item.node.name}</p>
            )}
          </Type>
        ))}
      </TypesWrapper>
    </Wrapper>
  )
}

export default Filter