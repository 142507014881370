/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import ButtonLine from 'components/elements/ButtonLine'
import SingleProject from 'components/projects/SingleProject'
import Filter from 'components/projects/Filter'

// Third Party
import styled from 'styled-components'
import { isEmpty } from 'lodash'

const StyledProjects = styled.div``

const Projects = ({ className }) => {
  const {
    projects: {
      edges: projects
    },
    types,
    fases
  } = useStaticQuery(graphql`{
    projects: allWordpressWpProject {
      edges {
        node {
          ...ProjectFrag
        }
      }
    }

    types: allWordpressWpTypeBouw {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }

    fases: allWordpressWpFase(sort: {order: DESC, fields: count}) {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
  }`)

  const chunk = (array, chunkSize) => {
    const R = []
    for (let i = 0; i < array.length; i += chunkSize) {
      R.push(array.slice(i, i + chunkSize));
    }
    return R;
  }

  const [chunkSize, setChunkSize] = useState(9)

  const updateChunk = () => {
    setChunkSize(chunkSize + 6)
  }

  const [filter, setFilter] = useState([])
  const [filter1, setFilter1] = useState([])

  let list = null

  if (!isEmpty(filter)) {
    list = projects.filter(n => n.node.type_bouw.find(g => filter.map(f => f.value).includes(g)))

    if (!isEmpty(filter1)) {
      list = list.filter(n => n.node.fase.find(g => filter1.map(f => f.value).includes(g)))
    }

  } else {
    list = projects

    if (!isEmpty(filter1)) {
      list = list.filter(n => n.node.fase.find(g => filter1.map(f => f.value).includes(g)))
    }
  }

  return (
    <StyledProjects className={`${className ? `${className}` : ``}`}>
      <div className="d-flex justify-content-between pb-5">
        <Filter fases data={fases} filter={filter1} setFilter={setFilter1} className="pb-3" />
        <Filter types data={types} filter={filter} setFilter={setFilter} className="pb-3" />
      </div>
      <div className="row">
        {!chunk(list, chunkSize)[0] ? (
          <div className="col-12 d-flex justify-content-center mt-5 py-5">
            <p className="text-uppercase font-weight-xl font-size-xl">Geen resultaten</p>
          </div>
        ) : (
          <>
            {chunk(list, chunkSize)[0].map(project => (
              <SingleProject className="col-md-6 col-lg-4 px-4" project={project} key={project.node.wordpress_id} />
            ))}
            <div className="col-12 pb-5 mb-5 d-flex justify-content-center">
              {chunk(list, chunkSize).length > 1 && (
                <ButtonLine isCustom className="px-4 py-1" onClick={() => updateChunk()}>
                  <p onKeyDown={() => updateChunk()} onClick={() => updateChunk()} className="mb-0 color-text-contrast font-weight-xl">Laad meer projecten</p>
                </ButtonLine>
              )}
            </div>
          </>
        )}
      </div>
    </StyledProjects>
  )
}

export default Projects