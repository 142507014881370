/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Projects from 'components/projects/Projects'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...ProjectsFrag
    }
  }
`

const ProjectsTemplate = ({
  data: {
    page: {
      path,
      acf: {
        banner
      },
      yoastMeta
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />

      <Hero content={banner} />

      <div className="container pt-5">
        <Projects />
      </div>
    </Layout>
  )
}

export default ProjectsTemplate
