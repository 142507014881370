/* eslint-disable react/no-array-index-key */
import React from 'react'
import Img from 'gatsby-image'

// Images
import SSBArrowRight from 'img/SSB_arrow_right.svg'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'
import CustomLink from 'components/shared/CustomLink'

// Third party
import styled from 'styled-components'

const Wrapper = styled.div`
  box-shadow: 0px 0px 30px -15px rgba(0,0,0,0.75);
`

const StyledImg = styled(Img)`
  transition: 0.25s;
  width: 100%;
  height: 100%;
  min-height: 221px;
  max-height: 221px;
`

const ImgContainer = styled(CustomLink)`
  overflow: hidden;
  display: flex;

  &:hover {
    ${StyledImg} {
      transform: scale(1.05);
    }
  }
`

const ContentContainer = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xm};
  }

  h3 {
    font-size: ${props => props.theme.font.size.sm};
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  top: 35px;

  p {
    white-space: nowrap;
    font-size: ${props => props.theme.font.size.m};
  }
`

const Points = styled.div`
  p {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 3px;
      width: 3px;
      left: 0;
      top: 8px;
      border-radius: 100px;
      background-color: ${props => props.theme.color.main};
    }
  }
`

const StyledSingleProject = styled.div`
  padding-bottom: 100px;
  /* padding-left: 50px;
  padding-right: 50px; */
`

const SingleProject = ({ className, project }) => {
  return (
    <StyledSingleProject className={`${className ? `${className}` : ``}`}>
      <Wrapper>
        <ImgContainer to={project.node.path}>
          <StyledImg fluid={project.node.acf.images.small_images.localFile.childImageSharp.fluid} alt="" />
        </ImgContainer>
        <ContentContainer className="px-4 pt-4 pb-3">
          <h2 className="color-text-gray text-uppercase font-weight-xl">{project.node.acf.info.title}</h2>
          <h3 className="pb-4 color-text-gray text-uppercase font-weight-s">{project.node.acf.info.place}</h3>
          <Points>
            {project.node.acf.info.what_we_do.map((item, index) => (
              <p key={index} className="mb-0 pl-2 color-text-contrast">{item.text}</p>
            ))}
          </Points>
        
          <div className="d-flex justify-content-center">
            <StyledButtonDefault to={project.node.path} className="position-relative px-1 py-1">
              <p className="mb-0 font-weight-xl">Bekijk project</p>
              <img className="ml-5" src={SSBArrowRight} alt="" />
            </StyledButtonDefault>
          </div>
        </ContentContainer>
      </Wrapper>
    </StyledSingleProject>
  )
}

export default SingleProject